import Vue from 'vue'
import Router from 'vue-router'
//  import Home from '../components/Home.vue'
Vue.use(Router)
export default new Router({
    routes:[
        {
            path:'/home',
            // component:()=> import('@/components/Home') //路由懒加载
            component:resolve => require(['@/components/Home'],resolve) // 异步组件
        },
        {
            path:'/',
            redirect:'/login',
            component:()=> import('@/components/Login') //路由懒加载
        },
        {
            path:'/login',
            name:'/login',
            component:()=> import('@/components/Login') //路由懒加载

        },
        {
            path:'/notFound',
            name:'/notFound',
            component:()=> import('@/components/NotFound') //路由懒加载
           
        },{
            path:'/home',
            name:'二级菜单',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/home/welcome',  
                    name:'首页',
                    component:()=> import('@/pages/welcome')
                },
                {
                    path:'/home/main',  
                    name:'工作台',
                    component:()=> import('@/pages/main')
                },
                {
                    path:'/home/erp',  
                    name:'仓库/物流',
                    component:()=> import('@/pages/erp')
                },
                {
                    path:'/home/resources',  
                    name:'资源库',
                    component:()=> import('@/pages/resources')
                },
                {
                    path:'/home/finance',  
                    name:'财务中台',
                    component:()=> import('@/pages/finance')
                },
                {
                    path:'/home/tools',  
                    name:'工具',
                    component:()=> import('@/pages/tools')
                }
            ]
        },{
            path:'/home',
            name:'企业管理Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/framework/company',  
                    name:'企业管理',
                    component:()=> import('@/pages/framework/company/company')
                }
            ]
        },{
            path:'/home',
            name:'部门管理Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/framework/dept',  
                    name:'部门管理',
                    component:()=> import('@/pages/framework/dept/dept')
                }
            ]
        },{
            path:'/home',
            name:'权限管理Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/framework/permission',  
                    name:'权限管理',
                    component:()=> import('@/pages/framework/permission/permission')
                }
            ]
        },{
            path:'/home',
            name:'岗位管理Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/framework/role',  
                    name:'岗位管理',
                    component:()=> import('@/pages/framework/role/role')
                }
            ]
        },{
            path:'/home',
            name:'用户管理Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/framework/user',  
                    name:'用户管理',
                    component:()=> import('@/pages/framework/user/user')
                }
            ]
        },{
            path:'/home',
            name:'数据字典Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/tools/dictionary',  
                    name:'数据字典',
                    component:()=> import('@/pages/tools/dictionary/dictionary')
                }
            ]
        },{
            path:'/home',
            name:'数据管理Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/framework/datamanage',  
                    name:'数据管理',
                    component:()=> import('@/pages/framework/datamanage/datamanage')
                }
            ]
        },{
            path:'/home',
            name:'型号Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/erp/productModel',  
                    name:'型号',
                    component:()=> import('@/pages/erp/productModel/productModel')
                }
            ]
        },{
            path:'/home',
            name:'套餐Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/erp/productPackage',  
                    name:'套餐',
                    component:()=> import('@/pages/erp/productPackage/productPackage'),
                }
            ]
        },{
            path:'/home',
            name:'产品Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/erp/product',  
                    name:'产品',
                    component:()=> import('@/pages/erp/product/product'),
                }
            ]
        },{
            path:'/home',
            name:'产品详情Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/erp/productDetail',  
                    name:'产品详情',
                    component:()=> import('@/pages/erp/product/productDetailList'),
                }
            ]
        },{
            path:'/erp/productUpload',
            name:'产品上传',
            component:()=> import('@/pages/erp/product/productUpload')
        },{
            path:'/home',
            name:'品牌Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/thirdParty/brand',  
                    name:'品牌',
                    component:()=> import('@/pages/thirdParty/brand/brand')
                }
            ]
        },{
            path:'/home',
            name:'供应商Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/thirdParty/supplier',  
                    name:'供应商',
                    component:()=> import('@/pages/thirdParty/supplier/supplier')
                }
            ]
        },{
            path:'/home',
            name:'台账Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/finance/standingBook',  
                    name:'台账功能',
                    component:()=> import('@/pages/finance/standingBook')
                }
            ]
        },{
            path:'/home',
            name:'业绩统计Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/statistics/performanceStatistics',  
                    name:'业绩统计',
                    component:()=> import('@/pages/statistics/performanceStatistics')
                }
            ]
        },{
            path:'/home',
            name:'客户录入Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/passengerFlow/addCustomer',  
                    name:'客户录入',
                    component:()=> import('@/pages/passengerFlow/addCustomer')
                }
            ]
        },{
            path:'/home',
            name:'未分配客户Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/passengerFlow/undistributedCustomer',  
                    name:'未分配客户',
                    component:()=> import('@/pages/passengerFlow/undistributedCustomer')
                }
            ]
        },{
            path:'/home',
            name:'运营HomeAll',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/passengerFlow/allCustomer',  
                    name:'运营All',
                    component:()=> import('@/pages/passengerFlow/allCustomer')
                }
            ]
        },{
            path:'/home',
            name:'未跟进客户Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/stylist/nonFollowCustomer',  
                    name:'未跟进客户',
                    component:()=> import('@/pages/stylist/nonFollowCustomer')
                }
            ]
        },{
            path:'/home',
            name:'待成交客户Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/stylist/pendingCustomer',  
                    name:'待成交客户',
                    component:()=> import('@/pages/stylist/pendingCustomer')
                }
            ]
        },{
            path:'/home',
            name:'标记客户Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/stylist/markCustomer',  
                    name:'标记客户',
                    component:()=> import('@/pages/stylist/markCustomer')
                }
            ]
        },{
            path:'/home',
            name:'进行中客户Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/stylist/underwayCustomer',  
                    name:'进行中客户',
                    component:()=> import('@/pages/stylist/underwayCustomer')
                }
            ]
        },{
            path:'/home',
            name:'设计HomeAll',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/stylist/allCustomer',  
                    name:'设计All',
                    component:()=> import('@/pages/stylist/allCustomer')
                }
            ]
        },
        // {
        //     path:'/scheme/shareScheme',
        //     name:'方案分享',
        //     component:()=> import('@/pages/stylist/shareScheme/schemeListShare') //路由懒加载
        // },
        {
            path:'/home',
            name:'新派发客户Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/technical/newDistributionCustomer',  
                    name:'新派发客户',
                    component:()=> import('@/pages/technical/newDistributionCustomer')
                }
            ]
        },{
            path:'/home',
            name:'未落地客户Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/technical/unregisteredCustomer',  
                    name:'未落地客户',
                    component:()=> import('@/pages/technical/unregisteredCustomer')
                }
            ]
        },{
            path:'/home',
            name:'进度客户Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/technical/flowCustomer',  
                    name:'进度客户',
                    component:()=> import('@/pages/technical/flowCustomer')
                }
            ]
        },{
            path:'/home',
            name:'技术HomeAll',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/technical/allCustomer',  
                    name:'技术All',
                    component:()=> import('@/pages/technical/allCustomer')
                }
            ]
        },{
            path:'/home',
            name:'找师傅Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/technical/installer',  
                    name:'找师傅',
                    component:()=> import('@/pages/technical/installer/installer')
                }
            ]
        },{
            path:'/home',
            name:'师傅库Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/technical/installerStock',  
                    name:'师傅库',
                    component:()=> import('@/pages/technical/installer/installerStock')
                }
            ]
        },{ 
            path:'/home',
            name:'生成清单Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/transportation/createBill',  
                    name:'生成清单',
                    component:()=> import('@/pages/transportation/createBill')
                }
            ]
        },{ 
            path:'/home',
            name:'预览清单Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/transportation/unshippedBill',  
                    name:'未发货清单',
                    component:()=> import('@/pages/transportation/unshippedBill')
                }
            ]
        },{
            path:'/home',
            name:'已发货清单Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/transportation/deliveredBill',  
                    name:'已发货清单',
                    component:()=> import('@/pages/transportation/deliveredBill')
                }
            ]
        },{
            path:'/home',
            name:'施工标准Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/resourceLibrary/standard',  
                    name:'施工标准',
                    component:()=> import('@/pages/resourceLibrary/standard/standard')
                }
            ]
        },{
            path:'/home',
            name:'安装教程Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/resourceLibrary/install',  
                    name:'安装教程',
                    component:()=> import('@/pages/resourceLibrary/install/install')
                }
            ]
        },{
            path:'/home',
            name:'入网教程Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/resourceLibrary/network',  
                    name:'入网教程',
                    component:()=> import('@/pages/resourceLibrary/network/network')
                }
            ]
        },{
            path:'/home',
            name:'文章Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/resourceLibrary/article',  
                    name:'文章',
                    component:()=> import('@/pages/resourceLibrary/article/article')
                }
            ]
        },{
            path:'/home',
            name:'精选案例Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/resourceLibrary/projectCase',  
                    name:'精选案例',
                    component:()=> import('@/pages/resourceLibrary/projectCase/projectCase')
                }
            ]
        },        
        {
            path:'/share/shareTechnology',
            name:'技术分享',
            component:()=> import('@/pages/shareInfo/shareResources/shareTechnology') //路由懒加载
        }, 
        {
            path:'/share/articleShare',
            name:'文章Home',
            component:()=> import('@/pages/shareInfo/shareResources/shareArticle') //路由懒加载
        }, {
            path:'/share/standardShare',
            name:'产品施工标准',
            component:()=> import('@/pages/shareInfo/shareOrder/technology/shareStandard') //路由懒加载
        },{
            path:'/share/AIDesign',
            name:'智能设计',
            // component:()=> import('@/pages/shareInfo/shareOrder/order/schemeDetail') //路由懒加载
            component:()=> import('@/pages/shareInfo/shareOrder/order/previewSchemeAIPoint')
        },
        {
            path:'/share/clientOrder',
            name:'客户订单Home',
            component:()=> import('@/pages/shareInfo/shareOrder/order/shareClientOrder')
        },
        {
            path:'/share/currentScheme',
            name:'当前方案Home',
            component:()=> import('@/pages/shareInfo/shareOrder/order/shareCurrentScheme')
        },{
            path:'/share/scheme',
            name:'客户方案Home',
            // component:()=> import('@/pages/shareInfo/shareOrder/order/schemeDetail') //路由懒加载
            component:()=> import('@/pages/shareInfo/shareOrder/order/schemeDetailCard')
        } ,{
            path:'/share/preview',
            name:'客户方案ExcelHome',
            // component:()=> import('@/pages/shareInfo/shareOrder/order/schemeDetail') //路由懒加载
            component:()=> import('@/pages/shareInfo/shareOrder/order/previewScheme')
        },{
            path:'/share/qcCard',
            name:'方案汇总Home',
            component:()=> import('@/pages/shareInfo/shareOrder/order/qcCard') //路由懒加载
        }
        ,{
            path:'/share/schemeSummary',
            name:'方案汇总Home',
            component:()=> import('@/pages/shareInfo/shareOrder/order/schemeSummaryCard') //路由懒加载
        },  
        {
            path:'/share/installShare',
            name:'安装记录Home',
            component:()=> import('@/pages/shareInfo/shareOrder/technology/shareInstall') //路由懒加载
        },  
        {
            path:'/share/measuredShare',
            name:'测量记录Home',
            component:()=> import('@/pages/shareInfo/shareOrder/technology/shareMeasured') //路由懒加载
        },  
        {
            path:'/share/freightShare',
            name:'发货记录Home',
            component:()=> import('@/pages/shareInfo/shareOrder/erp/shareFreight') //路由懒加载
        },  
        {
            path:'/share/logShare',
            name:'跟进记录Home',
            component:()=> import('@/pages/shareInfo/shareOrder/order/shareLog') //路由懒加载
        },  
        {
            path:'/share/productImageShare',
            name:'产品图册Home',
            component:()=> import('@/pages/shareInfo/shareOrder/erp/shareProductImage') //路由懒加载
        },  
        {
            path:'/share/productShare',
            name:'产品Share',
            component:()=> import('@/pages/shareInfo/shareResources/shareProduct') //路由懒加载
        },{
            path:'/home',
            name:'任务管理Home',
            component:()=> import('@/components/Home'),
            children:[
                {
                    path:'/task/taskList',  
                    name:'任务管理',
                    component:()=> import('@/pages/task/task')
                }
            ]
        },
        { path: '*', name: 'NotFound',  hidden: true, component: () => import('@/components/NotFound')}
        
    ],
    mode:'history'
})